<template>
    <BackofficeBase :loader_prop="loader_prop">

       

   
    <div>
        <p>{{view_data.all_distribution_point}} :מספר נקודות חלוקה </p>
        <p>{{view_data.packages_waiting}} :מספר חבילות ממתינות</p>
        <p>{{view_data.packages_collected}} :מספר חבילות שנמסרו</p>
        <p>{{view_data.free_boxs}} :מספר תיבות פנויות</p>
        <p>{{view_data.catch_boxs}} :מספר תיבות תפוסות</p>
        <p>{{view_data.endusers}} :מספר לקוחות קצה</p>
        <p>{{view_data.units}} :מספר יחידות</p>
        <p>{{view_data.boxes_j}} :מספר תיבות ג'מבו</p>
        <p>{{view_data.boxes_l}} :מספר תיבות גדולות</p>
        <p>{{view_data.boxes_m}} :מספר תיבות בינוניות</p>
        <p>{{view_data.boxes_s}} :מספר תיבות קטנות</p>
        <!-- <p> :מספר תיבות תקולות</p> -->
    </div>
    <p></p>
    </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";


export default {
  name: 'BackofficeStatistics',
  components: {
    BackofficeBase,
   },
    data() {
      return {
        loader_prop : true,
        view_data : [],
        // id = this.$route.query.id

      }
    },
    async mounted() {
      this.id = this.$route.query.id


      let backendModel = new BackendModel()
      let view_data = await backendModel.backendRequest("/Api/service/backoffice/admin_get_statistics",{})
      this.view_data = view_data.data.view_data
      this.loader_prop = false
      console.log(view_data)
    },
    methods :{
  
    }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

